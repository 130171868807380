// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.lagondola-euskirchen.de/api/', // 'http://localhost:8000/api/', // 
  BASE_URL: 'https://api.lagondola-euskirchen.de', //'http://localhost:8000'
  DEFAULT_LANG: 'de',
  APP_VERSION: '6.1.3', // change app version to force clear cache
  GOOGLE_COUNTRIES: ["de"], // for google address
  GOOGLE_LOGIN_WEB_CLIENT_ID: '65878389371-fevipp0727iamaaimgfrtqtbo20hcnhv0.apps.googleusercontent.com',
  FACEBOOK_LOGIN_APP_ID: '10322122325652334',
  // theme configuration
  DEFAULT_PRIMARY_COLOR: '#cc1fb5',
  DEFAULT_PRIMARY_TEXT_COLOR: '#cc8a1f',
  // LAST_MADE_CHANGE:'v5.2.7 : Bff board changes and Millennio board changes + ashan new changes + Praharsha modal size increased'
  // LAST_MADE_CHANGE:'v5.2.8 : Issues fixed and implemented new changes in millennio board'
  // LAST_MADE_CHANGE:'v5.2.9 : New reorder feature implemented and new changes in millennio board'
  // LAST_MADE_CHANGE:'v5.2.10 : new topping option limit feature implemented, 5.2.11 : Praharsha new cart icon design'
  // LAST_MADE_CHANGE:'v5.2.12 : postal code request feature implemented for mobile multiple restaurants mode',
  // LAST_MADE_CHANGE:'v5.2.13 : Coupon code moved to checkout page and decide quantity before add sizes to cart functions implemented',
  // LAST_MADE_CHANGE:'v5.2.14 :  Aligned the open closed dot on home-single',
  // LAST_MADE_CHANGE:'v5.2.15 : Address clear if the restaurant is not delivering to the current location when hit find my location, avoided clearing the address input',
  // LAST_MADE_CHANGE:'v5.2.16 : Preorder issue fix caused ASAP option missing issue fixed',
  // LAST_MADE_CHANGE:'v6.0.1 : Uber Direct intergration',
  // LAST_MADE_CHANGE:'v6.1.0 : Uber Direct intergration issues fixes, other changes from millennio by ashan',
  //LAST_MADE_CHANGE:'v6.1.2 : Implement dish quantity change spiner in topping wizard popup',
  LAST_MADE_CHANGE:'v6.1.3 : Fixed default popup topping count increase and decrease issue, called to the correct function',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
